<template>
  <div class="query-top">
    <div class="query-flex">
      <div>{{ queryInfo.nation }}</div>
      <div>{{ queryInfo.weight }}kg</div>
    </div>
    <div class="query-text">
      <div>计费方式</div>
      <div>
        报价不含目的地关税（包税线路除外）,不含偏远或超重超长费等，除EMS,60*60*60以内不算体积，其他途径均需计算体积
      </div>
      <!-- <div>首重=500g以内，续重=每增加500g的费用，单位：元</div> -->
    </div>
    <div class="query-list">
      <div class="query-list-text">
        <ul>
          <!-- 欧洲F专线小包 -->
          <li v-for="item in Country" :key="item.id">
            <div class="text-title">
              <span>{{ item.途径 }}</span>
              <div class="red">{{ item.运费情况 }}</div>
              <p>
                {{ item.具体情况 }}
              </p>
              <div>
                {{ item.拒绝情况 }}
              </div>
            </div>
            <div class="text-right">
              <div class="flex1 text-right2">
                <div>预计</div>
                <div>{{ item.预估价格 }}</div>
              </div>
            </div>
          </li>
          <!-- 欧洲集运空派 -->
          <li v-for="item in Country2" :key="item.id">
            <div class="text-title">
              <span>{{ item.途径 }}</span>
              <div class="red">{{ item.运费情况 }}</div>
              <p>
                {{ item.具体情况 }}
              </p>
            </div>
            <div class="text-right">
              <!-- <div class="flex1 text-rigjt1">
                <div>
                  <span>首重：</span>
                  <span>{{ item.首重 }}</span>
                </div>
                <div>
                  <span>续重：</span>
                  <span>{{ item.续重 }}</span>
                </div>
              </div> -->
              <div class="flex1 text-right2">
                <div>预计</div>
                <div>{{ item.预估价格 }}</div>
              </div>
            </div>
          </li>
          <!--英国空派集运  -->
          <li v-for="item in Country3" :key="item.id">
            <div class="red">{{ item.运费情况 }}</div>
            <div class="text-title">
              <span>{{ item.途径 }}</span>
              <p>
                {{ item.具体情况 }}
              </p>
               <div>
                {{ item.拒绝情况 }}
              </div>
            </div>
            <div class="text-right">
              <!-- <div class="flex1 text-rigjt1">
                <div>
                  <span>首重：</span>
                  <span>{{ item.首重 }}</span>
                </div>
                <div>
                  <span>续重：</span>
                  <span>{{ item.续重 }}</span>
                </div>
              </div> -->
              <div class="flex1 text-right2">
                <div>预计</div>
                <div>{{ item.预估价格 }}</div>
              </div>
            </div>
          </li>
          <!--  欧洲集运空派-大货 -->
          <li v-for="item in Country4" :key="item.id">
            <div class="text-title">
              <span>{{ item.途径 }}</span>
              <div class="red">{{ item.运费情况 }}</div>
              <p>
                {{ item.具体情况 }}
              </p>
               <div>
                {{ item.拒绝情况 }}
              </div>
            </div>
            <div class="text-right">
              <!-- <div class="flex1 text-rigjt1">
                <div>
                  <span>首重：</span>
                  <span>{{ item.首重 }}</span>
                </div>
                <div>
                  <span>续重：</span>
                  <span>{{ item.续重 }}</span>
                </div>
              </div> -->
              <div class="flex1 text-right2">
                <div>预计</div>
                <div>{{ item.预估价格 }}</div>
              </div>
            </div>
          </li>
          <!--  欧铁集运 -->
          <li v-for="item in Country5" :key="item.id">
            <div class="text-title">
              <span>{{ item.途径 }}</span>
              <div class="red">{{ item.运费情况 }}</div>
              <p>
                {{ item.具体情况 }}
              </p>
               <div>
                {{ item.拒绝情况 }}
              </div>
            </div>
            <div class="text-right">
              <!-- <div class="flex1 text-rigjt1">
                <div>
                  <span>首重：</span>
                  <span>{{ item.首重 }}</span>
                </div>
                <div>
                  <span>续重：</span>
                  <span>{{ item.续重 }}</span>
                </div>
              </div> -->
              <div class="flex1 text-right2">
                <div>预计</div>
                <div>{{ item.预估价格 }}</div>
              </div>
            </div>
          </li>
          <!-- EMS -->
          <li v-for="item in Country6" :key="item.id">
            <div class="text-title">
              <span>{{ item.途径 }}</span>
              <div class="red">{{ item.运费情况 }}</div>
              <p>
                {{ item.具体情况 }}
              </p>
               <div>
                {{ item.拒绝情况 }}
              </div>
            </div>
            <div class="text-right">
              <!-- <div class="flex1 text-rigjt1">
                <div>
                  <span>首重：</span>
                  <span>{{ item.首重 }}</span>
                </div>
                <div>
                  <span>续重：</span>
                  <span>{{ item.续重 }}</span>
                </div>
              </div> -->
              <div class="flex1 text-right2">
                <div>预计</div>
                <div>{{ item.预估价格 }}</div>
              </div>
            </div>
          </li>
          <!-- 美国集运 -->
          <li v-for="item in Country7" :key="item.id">
            <div class="text-title">
              <span>{{ item.途径 }}</span>
              <div class="red">{{ item.运费情况 }}</div>
              <p>
                {{ item.具体情况 }}
              </p>
               <div>
                {{ item.拒绝情况 }}
              </div>
            </div>
            <div class="text-right">
              <!-- <div class="flex1 text-rigjt1">
                <div>
                  <span>首重：</span>
                  <span>{{ item.首重 }}</span>
                </div>
                <div>
                  <span>续重：</span>
                  <span>{{ item.续重 }}</span>
                </div>
              </div> -->
              <div class="flex1 text-right2">
                <div>预计</div>
                <div>{{ item.预估价格 }}</div>
              </div>
            </div>
          </li>
          <!-- 加拿大 -->
          <li v-for="item in Country8" :key="item.id">
            <div class="text-title">
              <span>{{ item.途径 }}</span>
              <div class="red">{{ item.运费情况 }}</div>
              <p>
                {{ item.具体情况 }}
              </p>
               <div>
                {{ item.拒绝情况 }}
              </div>
            </div>
            <div class="text-right">
              <!-- <div class="flex1 text-rigjt1">
                <div>
                  <span>首重：</span>
                  <span>{{ item.首重 }}</span>
                </div>
                <div>
                  <span>续重：</span>
                  <span>{{ item.续重 }}</span>
                </div>
              </div> -->
              <div class="flex1 text-right2">
                <div>预计</div>
                <div>{{ item.预估价格 }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn" @click="reset">
      <button>返回重查</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 欧洲F专线小包
      Country: [],
      // 欧洲集运空派
      Country2: [],
      // 英国空派集运
      Country3: [],
      // 欧洲集运空派-大货
      Country4: [],
      // 欧铁集运
      Country5:[],
      // EMS
      Country6:[],
      // 美国集运
      Country7:[],
      // 加拿大
      Country8:[],
      queryInfo: {},

    };
  },
  mounted() {
    let CountryData = JSON.parse(window.sessionStorage.getItem("CountryData"));
    let queryInfo = JSON.parse(window.sessionStorage.getItem("queryInfo"));
    this.Country = CountryData.欧洲F专线小包;
    console.log(this.Country,'this.Country');
    this.Country2 = CountryData.欧洲集运空派;
    this.Country3 = CountryData.英国空派集运;
    this.Country4 = CountryData.欧洲集运空派大货;
    this.Country5 = CountryData.欧铁集运;
    this.Country6 = CountryData.EMS;
    this.Country7 = CountryData.美国集运;
    this.Country8 = CountryData.加拿大;



    this.queryInfo = queryInfo;
  },
  methods: {
    reset() {
      this.$router.push("/country");
    },
  },
};
</script>

<style lang="scss" scoped>
.query-top {
  margin-top: 0.5rem;
  .query-flex {
    background-color: #e9556d;
    display: flex;
    justify-content: center;
    color: #ffffff;
    padding: 0.3rem 0;
    font-size: 0.3rem;
    & > div:nth-child(1) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > div:nth-child(2) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .query-text {
    text-align: center;
    & > div:nth-child(1) {
      margin: 0.5rem 0;
      font-size: 0.4rem;
      font-weight: bold;
    }
    & > div:nth-child(2) {
      font-size: 0.3rem;
      line-height: 200%;
      margin-bottom: 0.3rem;
    }
    & > div:nth-child(3) {
      font-size: 0.3rem;
      line-height: 200%;
      margin-bottom: 0.3rem;
      color: #c04d5d;
    }
  }
  .query-list {
    .query-list-text {
      ul {
        padding: 0;
        padding-bottom: 3rem;
        li {
          list-style: none;
          display: flex;
          border-bottom: 0.04rem solid black;
          .text-title {
            flex: 1;
            span {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              color: #0100fa;
              font-size: 0.4rem;
              font-weight: 600;
              &::before {
                content: "";
                display: inline-block;
                width: 0.3rem;
                height: 0.3rem;
                background-color: #0100fa;
                border-radius: 100%;
                margin-right: 0.2rem;
              }
            }
            p {
              width: 6rem;
              line-height: 150%;
              margin-right: 1.5rem;
              font-size: 0.3rem;
              margin-bottom: 0.1rem;
              margin-top: 0.1rem;
            }
          }
          .text-right {
            flex: 1;
            margin-top: 0.9rem;
            display: flex;
            .text-rigjt1 {
              font-size: 0.3rem;
              & > div:nth-child(1) {
                width: 2rem;
                margin-bottom: 0.1rem;
              }
            }
            .text-right2 {
              font-size: 0.4rem;
              margin-top: -0.1rem;
              color: #db6686;
              & > div:nth-child(1) {
                margin-bottom: 0.1rem;
              }
              & > div:nth-child(2) {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
  .btn {
    position: fixed;
    width: 100%;
    left: 0rem;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 90%;
      padding: 0.3rem 0;
      background-color: #ffffff;
      border: 0.05rem solid #ea546f;
      border-radius: 0.25rem;
      color: #ea546f;
      font-size: 0.4rem;
    }
  }
}
.flex1 {
  flex: 1;
}
.query-top .query-list .query-list-text ul li .text-title {
  margin: 0;
}
.red{
  color: #e9556d;
}
</style>